.footer_div {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  background-color: white;
  padding-left: 70px;
}

.higher_div {
  height: 50px;
  font-size: 18px;
  color:gray;
  padding-top: 12px;
}

.lower_div {
  height: 50px;
  font-size: 25px;
}

.ichb_logo {
    position: absolute;
    right: 0;
    height: 100px;
    width: 100px;
}